import type { BadgeColor } from '@/components/ui/Badge.vue';

export const useComparisonFilter = () => {
  const { areasToCompare } = storeToRefs(comparisonStore());
  const { updateAreasToCompare } = comparisonStore();

  const updateMultipleAreasToCompare = (options: { areaIds: number[]; periodStart: Date }) => {
    const colors: BadgeColor[] = ['teal', 'blue', 'purple', 'pink', 'red', 'orange'];
    let newAreasToCompare: { id: number; periodStart: Date; color: BadgeColor }[] = [];

    if (areasToCompare.value.length === 0) {
      newAreasToCompare = options.areaIds.map((areaId, index) => ({
        id: areaId,
        periodStart: options.periodStart,
        color: colors[index % colors.length],
      }));
    } else {
      const updatedAreas = options.areaIds.map((areaId, index) => ({
        id: areaId,
        periodStart: options.periodStart,
        color: colors[index % colors.length],
      }));

      newAreasToCompare = areasToCompare.value.filter((area) => options.areaIds.includes(area.id));

      updatedAreas.forEach((newArea) => {
        const existingIndex = newAreasToCompare.findIndex((area) => area.id === newArea.id);
        if (existingIndex !== -1) {
          newAreasToCompare[existingIndex] = newArea;
        } else {
          newAreasToCompare.push(newArea);
        }
      });
    }

    updateAreasToCompare(newAreasToCompare);
  };

  const updateAreaStartPeriod = (areaId: number, period: Date) => {
    const newAreasToCompare = areasToCompare.value.map((area) => {
      if (area.id === areaId) {
        return { ...area, periodStart: period };
      }
      return area;
    });
    updateAreasToCompare(newAreasToCompare);
  };

  const deleteArea = (areaId: number) => {
    const newAreasToCompare = areasToCompare.value.filter((area) => area.id !== areaId);
    updateAreasToCompare(newAreasToCompare);
  };

  return {
    updateMultipleAreasToCompare,
    updateAreaStartPeriod,
    deleteArea,
  };
};
